import React, { useEffect, useState } from 'react';
import AuthComponent from '../login';
import logo from '../../assets/logo.svg';
import { getAuth, User } from 'firebase/auth';
import ReactGA from "react-ga4";


const auth = getAuth();
const Header = () => {
  const [showAuth, setShowAuth] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  // Function to toggle the display of the authentication component
  const toggleAuth = () => {
    setShowAuth(!showAuth);
  };
  const handleSignOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error('Sign-Out Error:', error);
    }
  };

  useEffect(() => {
    console.log("INITIALIZE");
    ReactGA.initialize("G-P5TY7QHTYM");
    ReactGA.send({
      hitType: "pageview",
      page: window?.location?.pathname ?? "/",
    });
  }, []);

  return (
    <>
      {/* <div className="hidden md:flex bg-[#efd165] h-14 text-center justify-center items-center">
        <a
          className="text-black rounded-full py-2 mr-0 text-base font-bold transform"
          onClick={() => {
            ReactGA.event({
              category: "b2p_musicnerd",
              action: "click",
              label: "b2p_click_musicnerd"
            });
          }}
          href="https://musicnerd.onelink.me/7HPG/xsin13ua" target="_blank" rel="noreferrer">
          Guess tunes to win $50 Apple Gift Card, everyday!  🚀🎵
        </a>
      </div> */}
      <div className='w-full px-10 md:py-10 py-5 md:bg-[rgba(255,255,255,0)] bg-[rgba(239,209,101,1)] hidden md:block'>
        <div className='flex flex-row justify-between'>
          <img width="100" height="50" src={logo} alt='logo' />
          {user ?
            <div className='flex flex-row'>
              <p className='text-white pr-2 pt-2'>Welcome, {user.email}!</p>

              <button
                className="bg-[rgba(255,255,255,0.05)] border-1 border-[rgba(255,255,255,0.05)] text-[rgba(255,255,255,0.5)] rounded-lg py-2 px-3"
                onClick={handleSignOut}
              >
                Sign Out
              </button>
            </div>
            :
            <button
              className="bg-[rgba(255,255,255,0.05)] border-1 border-[rgba(255,255,255,0.05)] text-[rgba(255,255,255,0.5)] rounded-lg py-2 px-3"
              onClick={toggleAuth} // Toggle authentication component visibility
            >
              Login / Signup
            </button>
          }
        </div>
      </div>
      {showAuth && <AuthComponent setShowAuth={setShowAuth} user={user} setUser={setUser} />} {/* Show the AuthComponent when 'showAuth' is true */}
      <button className='md:hidden w-full px-10 md:py-14 py-5 bg-[#efd165]'>
      <a
          className="text-black rounded-full py-2 mr-0 text-base font-bold transform"
          onClick={() => {
            ReactGA.event({
              category: "b2p_musicnerd",
              action: "click",
              label: "b2p_click_musicnerd"
            });
          }}
          href="https://musicnerd.onelink.me/7HPG/xsin13ua" target="_blank" rel="noreferrer">
          Guess tunes to win $50 Apple Gift Card, everyday!  🚀🎵
        </a>
      </button>

    </>
  );
};

export default Header;