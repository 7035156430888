import React, { useState } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider, OAuthProvider, User } from 'firebase/auth';
import { initializeApp } from 'firebase/app';



const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(config);

const auth = getAuth();

type loginProps ={
  setShowAuth: (showAuth: boolean) => void;
  user: User | null;
  setUser: (user: User | null) => void;
 
}

const AuthComponent: React.FC<loginProps> = ({setShowAuth, user, setUser}) => {
  
  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      console.log(result.user);
      setShowAuth(false);
    } catch (error) {
      console.error('Google Sign-In Error:', error);
    }
  };

  const handleAppleSignIn = async () => {
    // You would implement Apple Sign-In here (requires additional setup)
    // Refer to Firebase documentation for Apple Sign-In setup.
    const provider = new OAuthProvider('apple.com');
    try {
      const result = await signInWithPopup(auth, provider);
      setUser(result.user);
      console.log(result.user);
      setShowAuth(false);

    } catch (error) {
      console.error('Apple Sign-In Error:', error);
    }
  };

  // const handleSignOut = async () => {
  //   try {
  //     await auth.signOut();
  //     setUser(null);
  //   } catch (error) {
  //     console.error('Sign-Out Error:', error);
  //   }
  // };

  return (
    <div>
      {/* Add trigger button to open the overlay */}
      {/* {showLayout && ( */}
      <div className="overlay" onClick={() => setShowAuth(false)}>
        <div className="container">
          <div className="layout">
            <p className="text-base font-bold mt-11" style={{ fontFamily: 'Grandstander' }}>
              Login / Signup
            </p>
            <button className="transparent-button" onClick={handleGoogleSignIn}>
              <img className='w-6 h-6' src="/GoogleIcon.svg" alt="Google Icon" />
              <p>Google</p>
            </button>
            <button className="transparent-button" onClick={handleAppleSignIn}>
              <img className='w-6 h-7' src="/AppleIcon.svg" alt="Apple Icon" />
              <p>Apple</p>
            </button>
          </div>
        </div>
      </div>
      {/* )} */}
    </div>

  );
};

export default AuthComponent;