import React, { useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './screens/Homepage';
import './index.css';

function App() {
  // useEffect(() => {
  //   document.getElementById("shareButton")?.addEventListener("click", function () {
  //     console.log("shareButton", navigator.share)
  //     if (navigator.share) {
  //       navigator.share({ text: shareMessage + " " + window.location.href })
  //         .then(() => console.log('Thanks for sharing!'))
  //         .catch(console.error);
  //     } else {
  //       (window as any).handleDesktopShare();
  //     }
  //   });
  //   // Allow users to click on the input and select all text easily
  //   if (document.getElementById("shareUrl")) {
  //     document.getElementById("shareUrl")?.addEventListener("click", function () {
  //       this.setSelectionRange(0, this.value.length);
  //     })

  //   }, [])

  return (
    <div className="App">
      <div className="h-screen w-screen flex justify-center flex-col relative"
      >
        <div className='w-full grow-0'>
          <Header />
        </div>
        <div className='grow overflow-scroll'>
          <Homepage />
        </div>
        {/* <div className='w-full grow-0 p-2 md:block hidden'>
          <Footer />
        </div> */}
      </div>

    </div>
  );
}

export default App;