import React, { useEffect, useState } from 'react'
import UserSentimentInput from './components/UserSentimentInput'
import SongList from './components/SongList'
import { getEmotionsSongs } from '../../Endpoints/songs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getAuth } from 'firebase/auth';



const notify = () => toast.error("Something went wrong, please try again later!", {
  position: toast.POSITION.TOP_CENTER,
});

const DUMMY_DATA = [
  {
    "platform": "AppleMusic",
    "artist_name": "ABBA",
    "song_name": "Arrival (Bonus Track Version)",
    "song_image": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/69/4d/b4/694db440-1fdd-0112-16a0-ae501501cb32/14UMGIM07610.rgb.jpg/400x400bb.jpg",
    "song_url": "https://music.apple.com/us/album/dancing-queen/1440820126?i=1440820267",
    "artist_image": ""
  },
  {
    "platform": "AppleMusic",
    "artist_name": "Guns N' Roses",
    "song_name": "Appetite for Destruction",
    "song_image": "https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/a0/4d/c4/a04dc484-03cc-02aa-fa82-5334fcb4bc16/18UMGIM24878.rgb.jpg/400x400bb.jpg",
    "song_url": "https://music.apple.com/us/album/sweet-child-o-mine/1377813284?i=1377813701",
    "artist_image": ""
  },
  {
    "platform": "Spotify",
    "artist_name": "Guns N' Roses",
    "song_name": "Appetite For Destruction",
    "song_image": "https://i.scdn.co/image/ab67616d0000b27321ebf49b3292c3f0f575f0f5",
    "song_url": "https://open.spotify.com/track/7snQQk1zcKl8gZ92AnueZW",
    "artist_image": "https://i.scdn.co/image/ab67616d0000b27321ebf49b3292c3f0f575f0f5"
  },
  {
    "platform": "AppleMusic",
    "artist_name": "Pink Floyd",
    "song_name": "Wish You Were Here",
    "song_image": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/49/ab/fe/49abfef6-0cd9-aa1f-05c3-3eb85d3fe3f5/886445635843.jpg/400x400bb.jpg",
    "song_url": "https://music.apple.com/us/album/wish-you-were-here/1065973975?i=1065973980",
    "artist_image": ""
  },
  {
    "platform": "Spotify",
    "artist_name": "Eagles",
    "song_name": "Hotel California (2013 Remaster)",
    "song_image": "https://i.scdn.co/image/ab67616d0000b2734637341b9f507521afa9a778",
    "song_url": "https://open.spotify.com/track/40riOy7x9W7GXjyGp4pjAv",
    "artist_image": "https://i.scdn.co/image/ab67616d0000b2734637341b9f507521afa9a778"
  },
  {
    "platform": "Spotify",
    "artist_name": "Adele",
    "song_name": "21",
    "song_image": "https://i.scdn.co/image/ab67616d0000b273164feb363334f93b6458d2a9",
    "song_url": "https://open.spotify.com/track/3bNv3VuUOKgrf5hu3YcuRo",
    "artist_image": "https://i.scdn.co/image/ab67616d0000b273164feb363334f93b6458d2a9"
  },
  {
    "platform": "Spotify",
    "artist_name": "ABBA",
    "song_name": "Arrival",
    "song_image": "https://i.scdn.co/image/ab67616d0000b27370f7a1b35d5165c85b95a0e0",
    "song_url": "https://open.spotify.com/track/0GjEhVFGZW8afUYGChu3Rr",
    "artist_image": "https://i.scdn.co/image/ab67616d0000b27370f7a1b35d5165c85b95a0e0"
  },
  {
    "platform": "Spotify",
    "artist_name": "Pink Floyd",
    "song_name": "Wish You Were Here",
    "song_image": "https://i.scdn.co/image/ab67616d0000b2731a84d71391df7469c5ab8539",
    "song_url": "https://open.spotify.com/track/6mFkJmJqdDVQ1REhVfGgd1",
    "artist_image": "https://i.scdn.co/image/ab67616d0000b2731a84d71391df7469c5ab8539"
  },
  {
    "platform": "AppleMusic",
    "artist_name": "Adele",
    "song_name": "21",
    "song_image": "https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/62/82/a5/6282a515-c95c-9e5e-3eef-4ef7b75639ca/191404113868.png/400x400bb.jpg",
    "song_url": "https://music.apple.com/us/album/someone-like-you/1544491232?i=1544491998",
    "artist_image": ""
  },
  {
    "platform": "AppleMusic",
    "artist_name": "Eagles",
    "song_name": "Hotel California",
    "song_image": "https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/88/16/2c/88162c3d-46db-8321-61f3-3a47404cfe76/075596050920.jpg/400x400bb.jpg",
    "song_url": "https://music.apple.com/us/album/hotel-california/635770200?i=635770202",
    "artist_image": ""
  }
]
const harmfulKeywords = ['Kill',
  'Murder',
  'Suicide',
  'Fight',
  'Attack',
  'Harm',
  'Hurt',
  'Assault',
  'Terror',
  'Bomb',
  'Sex',
  'Nude',
  'Naked',
  'XXX',
  'Porn',
  'Adult',
  'Hentai',
  'Erotica',
  'NSFW (Not Safe For Work)',
  'Not Safe For Work',
  'NSFW',
  'Explicit',
  'Depression',
  'Cry',
  'Tears',
  'Lonely',
  'Hopeless',
  'Despair',
  'Sorrow',
  'Grieve',
  'Melancholy',
  'RAGE',
  'SUICIDE',
  'VIOLENT SEX',
  'Rape',
  'MURDER',
  'Killing',
  'Homicide',
  'Slay',
  'Execute',
  'Murderous',
  'Homicidal',
  'Suicidal',
  'Self-harm',
  'Brawl',
  'Skirmish',
  'Combat',
  'Torture',
  'Terrorism',
  'Terrorize',
  'Intimidate',
  'Bombing',
  'Explosive',
  'Blast',
  'Intercourse',
  'Nudity',
  'Pornography',
  'Erotic'
]


const Homepage = () => {
  const [emotion, setEmotion] = useState('');
  const [songData, setSongData] = useState<any>([]);
  const [loader, setLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [alert, setAlert] = useState(false);

  function containsHarmfulKeyword(input: string) {
    const lowerCaseInput = input.toLowerCase();
    for (const keyword of harmfulKeywords) {
      if (lowerCaseInput.includes(keyword.toLowerCase()) || keyword.toLowerCase().includes(lowerCaseInput)) {
        return true;
      }
    }
    return false;
  }
  const getSongs = async (val?: string) => {
    const localEmotion = val ? val : emotion
    if (containsHarmfulKeyword(localEmotion)) {
      setAlert(true);
      return;
    }

    setAlert(false)

    setSongData([])
    try {
      setLoader(true)
      const user = getAuth().currentUser;
      const uidAsString = user ? user.uid.toString() : '';

      const response = await getEmotionsSongs(localEmotion, uidAsString);
      // api call
      let arr = response.data.data;
      console.log("first", response.data.data)
      if (response.data.data.length === 0) {
        setLoader(false);
        setShowToast(true);
        toast.error("No data available. Please try again later.", {
          position: toast.POSITION.TOP_CENTER,
        })
        return;
      }
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [arr[i], arr[j]] = [arr[j], arr[i]];
      }
      const randomSongs = arr.slice(0, Math.min(5, arr.length));
      // console.log("arr", randomSongs)
      setSongData(randomSongs)
      // console.log("data", response.data.data)
      setLoader(false)

    }
    catch (err) {
      console.log(err)
      setLoader(false)
      setShowToast(true);
      toast.error("Something went wrong, please try again later!", {
        position: toast.POSITION.TOP_CENTER,
      })
    }
  }
  return (
    <div className='p-4 text-white flex justify-between items-start w-full h-full flex-col md:flex-row'>
      <UserSentimentInput getSongs={getSongs} emotion={emotion} setEmotion={setEmotion} />
      <SongList songData={songData} loader={loader} alert={alert} />
      {showToast && <ToastContainer />}
    </div>
  )
}

export default Homepage